(function() {
    'use strict';

    angular
        .module('herabemanningApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$rootScope', '$scope','$state', '$translate', '$location', '$document'];

    function NavbarController ($rootScope, $scope, $state, $translate, $location, $document) {
        var vm = this;

        vm.$state = $state;

        vm.no = 'no';
        vm.en = 'en';
        vm.changeLanguage = changeLanguage;
        vm.goTo = goTo;
        

        console.log('$rootScope.language', $rootScope.language);


        activate();

        function activate() {

            //TODO: ...
        }

        function changeLanguage(langKey) {
            $translate.use(langKey);

            $rootScope.$broadcast('language-changed', {langkey: langKey});
        } 


        // When jumping to an anchor tag by doing $location.hash(data)
        // a problem arised where the fixed header positioning was hiding
        // content behind it. Using duScroll to scroll to target element
        // with a negative offset applied to fix this issue. (Stig)
        // https://github.com/oblador/angular-scroll
        function goTo(data) {
            // Get the header height within this function, as the height may
            // change on resolution or device orientation changes. (Stig)
            var headerHeight = document.getElementById('navbar-header').offsetHeight - 1;
            var $el = angular.element(document.getElementById(data));
            $document.scrollToElement($el, headerHeight);
        }
    }


})();
