(function() {
    'use strict';

    angular
        .module('herabemanningApp', [
            'ngStorage',
            'pascalprecht.translate',
            'ngAnimate',
            'ngAria',
            'ngCookies',
            'ngMessages',
            'ngResource',
            'ngSanitize',
            'ngTouch',
            'ui.router',
            'angular-loading-bar',
            'ngMap',
            'duScroll'
        ])
        .run(run);

    run.$inject = ['stateHandler'];

    function run(stateHandler) {
        stateHandler.initialize();
    }
})();
