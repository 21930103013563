
(function() {
    'use strict';

    angular
        .module('herabemanningApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];


    function stateConfig($stateProvider) {
        console.log('home.state');
        $stateProvider.state('home', {
            parent: 'app',
            url: '',
            views: {
                'content@': {
                    templateUrl: 'home.html',
                    controller: 'HomeController',
                    controllerAs: 'vm'
                }
            }
        });
    }

})();
