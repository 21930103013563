(function(module) {
try {
  module = angular.module('herabemanningApp');
} catch (e) {
  module = angular.module('herabemanningApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('navbar.html',
    '<header id="navbar-header"><a ng-click="vm.goTo(\'home\')"><img src="img/Hera-Logo-H.svg" onerror="this.src=\'img/Hera-Logo-H.png\'" class="logo" alt="Hera bemanning logo icon"><h1 class="logoname">Hera Bemanning</h1></a><nav><ul><li><a ng-click="vm.goTo(\'home\')">Hjem</a></li><li><a ng-click="vm.goTo(\'about\')">Om Hera</a></li><li><a ng-click="vm.goTo(\'contact\')">Kontakt</a></li></ul></nav></header>');
}]);
})();
