(function() {
    'use strict';

    angular
        .module('herabemanningApp')
        .config(stateConfig)
        .config(translateConfig);

    stateConfig.$inject = ['$stateProvider'];
    translateConfig.$inject = ['$translateProvider'];

    function stateConfig($stateProvider) {
        console.log('app.state');
        $stateProvider.state('app', {
            abstract: true,
            views: {
                'navbar@': {
                    templateUrl: 'navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                }
            }

        });
    }

    function translateConfig($translateProvider) {
        $translateProvider.translations('no', translationsNO);
        $translateProvider.translations('en', translationsEN);
        $translateProvider.preferredLanguage('no');
        $translateProvider.fallbackLanguage('no');        
        // Enable escaping of HTML
        $translateProvider.useSanitizeValueStrategy('escape');
    }

    var translationsNO = {
        HEADLINE: 'For en kul modul!',
        BUTTON_LANG_NO: 'Norsk',
        BUTTON_LANG_EN: 'Engelsk',

        FIND_JOBS_HEADLINE:'Finn jobb',
        FIND_JOBS_INFO:'Er du en dyktig bygg og anleggsarbeider på utkikk etter ny jobb eller har du lyst på erfaring fra bransjen? Send oss din CV så hjelper vi deg videre i din jobbkarriere innen bygg og anlegg!',
        FIND_JOBS_QUESTIONS: 'Hvordan er det å jobbe gjennom oss?',
        SEND_EMAIL:'Send e-post til cv@herabemanning.no',

        FIND_PERSONNEL_HEADLINE:'Finn personell',
        FIND_PERSONNEL_INFO:'Har du behov for dyktig personell innen bygg og anleggsbransjen? Send inn en uforpliktende forespørsel til oss. Vi svarer i løpet av 12 timer.',
        FIND_PERSONNEL_QUESTIONS: 'Les mer om hvordan vi kan hjelpe deg',
        SEND_EMAIL_2:'Send e-post til post@herabemanning.no',

        HERA_BYGG_ANLEGG:'Bemanning til bygg og anlegg',

        HERA_INFO:'Vi er et bemanningsbyrå spesialisert på bygg og anleggsbransjen og jobber innen privat og offentlig sektor. Vi fokuserer på god service, høy faglig kvalitet og trivsel i hverdagen.',
        HERA_INFO_1:'Med over 11 års erfaring fra bransjen har vi god forståelse for hva som kreves av innleide arbeidstakere. Vi er nøye med intervju: referansesjekk, kartlegging av erfaringer og kompetaser til arbeidere våre er i stor fokus. Samtidig er vi opptatt av å kjenne miljøet og kulturen i bedrifter som skal benytte fagpersonell fra oss. Slik sikrer vi at både kundene og personalet er fornøyd.',
        HERA_INFO_2:'Kontoret vårt ligger sentralt til på Ryen, med primærområder i Oslo og Akershus. ',
        HERA_CONTACT: 'Ta kontakt med oss!',

        HERA_JOB_HEADLINE:'Finn jobb med Hera Bemanning',
        HERA_JOB_INFO:'Leter du etter jobb innen bygg og anlegg? Vi har varierte oppdrag og trenger en bred stab med folk fra ulike fagbakgrunner. Vi leter alltid etter nytt personell innen følgende yrker: forskalingssnekkere, murere/flisleggere, stillasmontører, malere, sveisere/stålmontører, jernbindere, manitou truckførere, anleggsmaskin førere, snekkere og tømrere.',
        HERA_JOB_INFO_1:'På grunn av sikkerhet på byggeplasser er kommunikasjon en avgjørende faktor for at vi kan behandle søknaden din. Du bør snakke ett av de skandinaviske språkene eller godt engelsk for å søke hos oss.',
        HERA_JOB_INFO_2:'Straks du har sendt søknad og cv ser vi på utdanningen, jobberfaringen og sjekker hvilke kurs du eventuelt har tatt (f.eks. varmearbeider, fallsikringskurs, stillaskurs og liknende). Deretter kaller vi deg inn til intervju for å høre hva som interesserer deg og hva du har lyst til å jobbe med. Etter å ha sjekket minimum 2 referanser setter vi i gang med å finne kunder og oppdrag til deg der du vil trives.',
        HERA_JOB_INFO_3:'Vil du bli en del av teamet vårt? ',

        HERA_MANGLER_FOLK_HEADLINE:'Finn personell med Hera Bemanning',
        HERA_MANGLER_FOLK_INFO:'Er du usikker på behovet for fast arbeidskraft? Da kan et bemanningsbyrå være løsningen. Vi tilpasser tjenestene våre etter dine behov. Trenger du å leie inn noen på midlertidig basis finner vi en som passer til jobben. Ønsker du fast ansettelse kan vi hjelpe deg med å headhunte aktuelle kandidater.',
        HERA_MANGLER_FOLK_INFO_1:'Etter å ha fått din forespørsel kontakter vi deg for å bekrefte dette og ev. for å hente flere opplysninger. Deretter vil vi fortløpende holde dere oppdatert per e-post eller telefon. Vi vil bruke interne og eksterne midler for å finne rett kandidat. Når vi finner noen aktuelle kandidater arrangerer vi et møte for å presentere dem.',
        HERA_MANGLER_FOLK_INFO_2:'Vår lange erfaring fra bransjen og grundighet gjør oss trygge på at vi klarer å fylle bemanningsbehovet ditt.',

        ARCHIVES_HEADLINE: "Firmadokumenter",
        ARCHIVES_INFO: "Vil du ha innsyn til firmadokumentene våre før du inngår en avtale med oss? Se igjennom de siste opplysningene våre her:",
        ARCHIVES_1:"Attest for skatt og mva",
        ARCHIVES_2:"Godkjent hos arbeidstilsynet",
        ARCHIVES_3:"Brønnøysund - Firmaattest",
        ARCHIVES_4:"HMS kurs for ledere - Kursbevis",
        ARCHIVES_5:"Forsikring og pensjonsavtale",
        ARCHIVES_6:"Startbank registreringsbevis",
        ARCHIVES_7:"Bedrifthelsetjeneste",

        CONTACT: 'Kontakt',
        CONTACT_TELEPHONE: 'Telefon:',
        CONTACT_TELEPHONE_1: '(+47) 473 25 117',
        CONTACT_EMAIL: 'E-post:',
        CONTACT_EMAIL_1: 'post@herabemanning.no',
        CONTACT_EMAIL_2: 'cv@herabemanning.no',
        CONTACT_ADDRESS: 'Besøksadresse:',
        CONTACT_ADDRESS_2: 'Oluf Onsums vei 9, 0680 Oslo',
    };


    var translationsEN = {
        HEADLINE: 'What an awsome module!',
        BUTTON_LANG_EN: 'English',
        BUTTON_LANG_NO: 'Norwegian',

        FIND_JOBS_HEADLINE: 'Finn jobb',
        FIND_JOBS_INFO: 'Er du en dyktig bygg og anleggsarbeider på utkikk etter ny jobb eller ung og har lyst på bransjeerfaring? Send oss din CV så hjelper vi deg videre i din jobbkarriere innen bygg og anlegg!',
        FIND_JOBS_QUESTIONS: '> Hvordan er det å jobbe gjennom oss?',
        SEND_EMAIL: '> Send e-post til cv@herabemanning.no',

        FIND_PERSONNEL_HEADLINE: 'Finn personell',
        FIND_PERSONNEL_INFO: 'Har du behov for dyktig personell innen bygg og anleggsbransjen? Send inn en uforpliktende forespørsel til oss. Vi svarer i løpet av 12 timer.',
        FIND_PERSONNEL_QUESTIONS: '> Les mer om personalet vårt',
        SEND_EMAIL2: '> Send e-post til post@herabemanning.no',

    

    };

})();
