(function() {
    'use strict';

    angular
        .module('herabemanningApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$state', '$http', '$translate', '$location', '$document', '$window'];

    function HomeController ($scope, $state, $http, $translate, $location, $document, $window) {
        var vm = this;
        vm.title = "Home Controller";

        vm.$state = $state;
        vm.toogleDropdown = toogleDropdown;
        vm.toogleDropdown2 = toogleDropdown2;
        vm.hideInfo = true;
        vm.hideInfo2 = true;
        vm.goTo = goTo;
        vm.navigate = function(event, url) {
            $window.location.href = url;
        };

        $scope.$on('language-changed', function (event, args) {
            $translate.use(args.langkey);

        });

        activate();

        function activate() {
            //TODO: ..
            var contactElm = angular.element(document.getElementById('contact'));
            var contactElmHeight = contactElm.offsetHeight;
            var contactRightElm = contactElm.children().last();

            var mapstylingElm = angular.element(document.getElementsByClassName('mapstyling'));
            console.log(mapstylingElm);

  /*          contactRightElm.css('height', contactElmHeight);
            mapstylingElm.css('height', contactElmHeight);*/
        }

        function toogleDropdown() {
            if (vm.hideInfo) {
                vm.hideInfo = false;
                vm.rotate = 'rotate';
            } else {
                vm.hideInfo = true;
                vm.rotate = '';    
            }
        }

         function toogleDropdown2() {
            if (vm.hideInfo2) {
                vm.hideInfo2 = false;
                vm.rotate2 = 'rotate';
            } else {
                vm.hideInfo2 = true;  
                vm.rotate2 = '';  
            }
        }

        // When jumping to an anchor tag by doing $location.hash(data)
        // a problem arised where the fixed header positioning was hiding
        // content behind it. Using duScroll to scroll to target element
        // with a negative offset applied to fix this issue. (Stig)
        // https://github.com/oblador/angular-scroll
        function goTo(data) {
            // Get the header height within this function, as the height may
            // change on resolution or device orientation changes. (Stig)
            var headerHeight = document.getElementById('navbar-header').offsetHeight - 1;
            var $el = angular.element(document.getElementById(data));
            $document.scrollToElement($el, headerHeight);
        }

        // src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDag9Bkogolm3w22GsCDFOlbMwetGGKwNM&callback=initMap">

        vm.mapStyles = [
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#e8e8e8"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#cecece"
                    }
                ]
            },
            {
                "featureType": "landscape.natural.landcover",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#e8e8e8"
                    }
                ]
            },
            {
                "featureType": "landscape.natural.terrain",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#e8e8e8"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#29b473"
                    }
                ]
            },
            {
                "featureType": "poi.attraction",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ff0000"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#29b473"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#000000"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.place_of_worship",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#29b473"
                    }
                ]
            },
            {
                "featureType": "poi.school",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#29b473"
                    }
                ]
            },
            {
                "featureType": "poi.sports_complex",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#29b473"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#c8c8c8"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#000000"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "hue": "#00ff88"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#000000"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#000000"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "hue": "#00ff88"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#7bd9ef"
                    }
                ]
            }
        ];

    }
})();
